#ingredients {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem 1rem;
}

#directions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.alert-container {
    margin-top: -2rem;
    height: 3rem;
}